<template>
  <div class="pagination_box">
    <el-pagination background layout="sizes,prev, pager, next" :total="total" :current-page="pageNum" @current-change="currentChange" :page-size="pageSize" @size-change="sizeChange" :page-sizes="[10,20,50,100, 150, 200]" />
  </div>
</template>

<script>
export default {
  props:{
    pageNum:{
      type: Number,
      default: 1,
    },
    pageSize:{
      type: Number,
      default: 20,
    },
    total:{
      type: Number,
      default: 0,
    }
  },
  setup (props,{emit}) {
    const sizeChange = (size)=>{
      emit("update:pageSize",size);
      emit("change");
    }
    const currentChange = (num)=>{
      emit("update:pageNum",num);
      emit("change");
    }
    
    return {sizeChange,currentChange}
  }
}
</script>

<style lang="scss" scoped>
.pagination_box{
  display:flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>