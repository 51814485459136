import {post,get} from '@/axios'


export const login = (params)=>{
  return post("/supp/login",params)
}

export const getUserInfo = (params)=>{
  return get("/basedata/user/getUserInfo?systemCode=SUPP_COOPERATE",params)
}

export const logout = (params)=>{
  return post("/logout",params)
}

export const updatePassword = (params)=>{
  return post("/basedata/user/updatePassword",params)
}
