<script lang="jsx">
import {onMounted,ref } from "vue";
let component = {
  name: "DlmTable",
  props: { 
    flexible:{
      type: Boolean,
      default: true
    }, 
    scrollbarAlwaysOn:{
      type: Boolean,
      default: true
    },
    labels:{
      type: Array,
      default: ()=>[]
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  setup(props,{attrs,slots,emit,expose}){
    let tableRef = ref(null);
    let methodsArr = ['clearSelection','getSelectionRows','toggleRowSelection','toggleAllSelection','toggleRowExpansion','setCurrentRow','clearSort','toggleRowExpansion','clearFilter','doLayout','sort','scrollTo','setScrollTop','setScrollLeft',]
    let methods={};
    methodsArr.forEach((item)=>{
      methods[item] = function(){
        return tableRef.value && tableRef.value[item].apply(this,arguments)
      }
    })
    expose(methods)
    onMounted(()=>{
      let childrens = slots.default();
      let labels = [];
      childrens.forEach(item=>{
        if(item.props && !item.props.type && item.props.label){
          labels.push({
            label:item.props.label,
            checked:true
          })
        }
      })
      emit('update:labels',labels)
    })
    return ()=>{
      return <el-table ref={tableRef} {...props} {...attrs}>{slots}</el-table>
    }
  }, 
  components: {},

  computed: {},

  mounted() {
   
  },

  methods: {
    
  }
};
// let methods = ['clearSelection','getSelectionRows','toggleRowSelection','toggleAllSelection','toggleRowExpansion','setCurrentRow','clearSort','toggleRowExpansion','clearFilter','doLayout','sort','scrollTo','setScrollTop','setScrollLeft',]
// methods.forEach((item)=>{
//   component.methods[item] = function(){
//     return this.$refs['tableRef'][item].apply(this,arguments)
//   }
// })
export default component;
</script>
