<template>
  <div>
    <div class="topSearch">
      <div class="topSearch-slot">
        <slot></slot>
      </div>
      <div class="btns">
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button  @click="onRest">重置</el-button>
        <slot name="restAfter"></slot>
        <el-button type="text"  @click="onAdvancedSearch" v-if="$slots.bottom">高级查询
          <i :class="['el-icon-arrow-down icon-arrow',{arrow:height>0}]"></i>
        </el-button>
      </div>
    </div>
    <div class="bottom-slot_box" :style="[{height:height+'px'}]">
      <div class="bottom-slot" ref="bottomSlot">
        <slot name="bottom"></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 0
    };
  },

  components: {},

  computed: {},

  mounted() {
  },
  methods: {
    onRest() {
       this.$emit("reset")
    },
    onSearch() {
       this.$emit("search")
    },
    onAdvancedSearch() {
      if (this.height) {
        this.height = 0;
      } else {
        this.height = this.$refs["bottomSlot"].offsetHeight;
      }
      this.$emit("advancedSearch")

      this.$nextTick(()=>{
        setTimeout(()=>{
           this.$emit("openAdvancedSearch")
        },350)
      })
    }
  }
};
</script>
<style lang='scss' scoped>
 :deep(.el-form-item)  {
    margin-bottom: 10px;
  }
.topSearch {
 
  display: flex;
  align-items: center;
  .topSearch-slot {
    flex: 1;
  }
  .btns {
    display: flex;
    margin-bottom: 14px;
  }
  
}
.bottom-slot {
  padding-top: 15px;
    border-top: 1px solid rgba(0,0,0,0.06);

  }
.bottom-slot_box {
  overflow: hidden;
  transition: height 0.3s;

}
.icon-arrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
.arrow {
  transform: rotate(180deg);
}
</style>