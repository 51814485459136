<template>
  <el-dialog :modelValue="modelValue" title="修改密码" draggable width="600" @close="close">
    <el-form :model="params" :rules="rules" label-width="100px" ref="formRef">
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="params.newPassword" show-password placeholder="密码由数字与字母组成，且长度在6-16位之间"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="params.confirmPassword" type="password" show-password placeholder="请确认密码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">
          确 认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import message from "@/utils/message";
import { reactive, ref,computed } from "vue";
import { validatePsdReg } from "@/utils/validate/index.js";
import { updatePassword } from "@/api/login/login";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router =  useRouter();
    const store = useStore();
    const formRef = ref(null);
    const userInfo = computed(() => store.state.userInfo);
    const rules = {
      newPassword: [
        {
          required: true,
          message: "新密码不能为空",
          trigger: ["blur", "change"],
        },
        {
          validator: validatePsdReg,
          message: "密码由数字与字母组成，且长度在6-16位之间",
          trigger: ["blur", "change"],
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: "确认密码不能为空",
          trigger: ["blur", "change"],
        },
        {
          validator: validatePsdReg,
          message: "密码由数字与字母组成，且长度在6-16位之间",
          trigger: ["blur", "change"],
        },
      ],
    };
    const params = reactive({
      id: null,
      newPassword: null,
      confirmPassword: null,
      version: null,
    });

    const confirm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          if (params.newPassword == params.confirmPassword) {
            params.id = userInfo.value.id;
            params.version = userInfo.value.version;
            updatePassword(params).then((res) => {
              if (res.resCode == 0) {
                message.success("修改成功");
                router.replace({
                  path: "/login",
                });
              }
            });
          } else {
            message.error("确认密码与密码不一致!");
          }
        } else {
          console.log("error submit!");
        }
      });
    };
    const cancel = () => {
      close();
    };
    const close = () => {
      formRef.value.resetFields();
      emit("update:modelValue", false);
    };

    return { cancel, rules, params, formRef, confirm, close };
  },
};
</script>

<style lang="scss" scoped>
</style>