import {post,get} from '@/axios'


export const getListCght = (params)=>{
  return post("/suppcooperate/cght/list",params)
}

export const getDetailCght = (params)=>{
  return get("/suppcooperate/cght/getReport/"+params)
}

