import { ElMessage } from 'element-plus'

let messageInstance = null;
function message (options){
  if(messageInstance){
    messageInstance.close();
  }
  messageInstance = ElMessage(options)
}
['success' , 'warning' , 'info' , 'error'].forEach(type=>{
  message[type] = options => {
    if (typeof options === 'string') {
        options = {
            message: options
        }
    }
    options.type = type
    return message(options)
}
})

export default message;