<template>
  <div class="tagsRouter">
    <!-- <div :class="['tag_item tag_index',{tag_item_act:routerIndex == -1}]" @click="changeRouter('/index',{},-1)">
      <div class="iconfont">&#xe707;</div>
    </div> -->

    <div :class="['tag_item',{tag_item_act:routerIndex == index}]" v-for="(item,index) in routerList" :key="index" @click="changeRouter(item.path,item.query,index)">
      <p :class="['name',{tag_item_close:item.close}]">{{ item.name }}</p>
      <el-icon v-show="item.close" class="close" @click.stop="closeRouter(index)">
        <Close />
      </el-icon>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const routerIndex = ref(-2);
    const route = useRoute();
    const router = useRouter();
    const routerList = computed(() => store.state.tagsRouter);
    
    const setRouterIndex = ()=>{
      let index = routerList.value.findIndex((i) => i.path == route.path);
      if (route.path == "/index") {
        routerIndex.value = -1;
      } else if (index != -1) {
        routerIndex.value = index;
      }
    }
    const changeRouter = (path, query, index) => {
      routerIndex.value = index;
      router.push({
        path: path,
        query: query,
      });
    };
    const closeRouter = (index) => {
      let path = "/index";
      let query = {};
      if (index != 0) {
        path = routerList.value[index - 1].path;
        query = routerList.value[index - 1].query;
      }

      if (index == routerIndex.value) {
        if (index != 0) {
          routerIndex.value = index - 1;
        } else {
          routerIndex.value = -1;
        }
      }
      // routerList.value.splice(index,1)
      store.commit("tagsRouterSplice", index);
      router.replace({
        path: path,
        query: query,
      });
    };

    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        setRouterIndex()
      },
      { immediate: true }
    );
    onMounted(() => {
      setRouterIndex()
    });
    return { routerList, routerIndex, changeRouter, closeRouter };
  },
};
</script>

<style lang="scss" scoped>
.tagsRouter {
  padding: 5px 20px 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: nowrap;
  .tag_index {
  }
  .tag_item {
    position: relative;
    // color: #ff6a00;
    // border-bottom: 2px solid #ff6a00;
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;
    padding: 0 10px;
    padding-bottom: 3px;
    // display: flex;
    // align-items: center;
    border-bottom: 2px solid transparent;
    color: #909399;
    line-height: 20px;
    height: 20px;
    .name {
      // padding-right: 10px;
    }
    .tag_item_close {
      padding-right: 10px;
    }
    .close {
      position: absolute;
      // transform: translateY(-50%);
      top: 4px;
      right: 0;
      display: none;
    }
    &:hover {
      color: #ff6a00;
      border-bottom: 2px solid #ff6a00;
      .close {
        display: block;
      }
    }
  }

  .tag_item_act {
    color: #ff6a00;
    border-bottom: 2px solid #ff6a00;
  }
}
</style>