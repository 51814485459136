import {
  createStore
} from 'vuex'
let tagsRouter = localStorage.getItem("tagsRouter");
tagsRouter = tagsRouter ? JSON.parse(tagsRouter) : []
if (tagsRouter.length == 0) {
  tagsRouter.push({
    close: false,
    name: "采购合同",
    path: "/purchaseContract",
    query: {},
  })
}
export default createStore({
  state: {
    userInfo: {},
    tagsRouter: tagsRouter
  },
  getters: {},
  mutations: {
    userInfo(state, info) {
      state.userInfo = info;
    },
    tagsRouterSplice(state, index) {
      state.tagsRouter.splice(index, 1);
      localStorage.setItem("tagsRouter", JSON.stringify(state.tagsRouter))
    },
    addTagsRouter(state, {
      name,
      path,
      query
    }) {
      let findIndex = state.tagsRouter.findIndex(i => i.path == path);
      if (findIndex != -1) {
        state.tagsRouter[findIndex] = {
          close: true,
          name: name,
          path: path,
          query: query,
        }
      } else {
        state.tagsRouter.push({
          close: true,
          name: name,
          path: path,
          query: query,
        })
      }
      localStorage.setItem("tagsRouter", JSON.stringify(state.tagsRouter))
    },
  },
  actions: {},
  modules: {}
})