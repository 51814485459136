<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
</template>

<script>
import { getUserInfo } from "@/api/login/login";
import { onMounted, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      getUserInfo().then((res) => {
        if (res.resCode == 0) {
          store.commit("userInfo", res.data);
        }
      });
    });
    return {};
  },
  components: {},
};
</script>
<style lang="scss">
.el-scrollbar__thumb{
  background-color: #000000 !important;
}
.shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
}
.el-dialog__header {
  background-color: #eeeeee;
  margin-right: 0 !important;
  padding: 10px 16px !important;
  .el-dialog__headerbtn {
    top: -2px !important;
  }
  .el-dialog__title {
    font-size: 15px !important;
    font-weight: bold !important;
  }
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont"; /* Project id 4177136 */
  src: url("//at.alicdn.com/t/c/font_4177136_dtwmwnnyzsf.woff2?t=1692244067719")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_4177136_dtwmwnnyzsf.woff?t=1692244067719")
      format("woff"),
    url("//at.alicdn.com/t/c/font_4177136_dtwmwnnyzsf.ttf?t=1692244067719")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// .topSearch .search_w{
//   width: 300px;
// }

.topSearch,
.bottom-slot {
  .el-form-item {
    .el-form-item__label {
      border: 1px solid #dcdfe6;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      border-right: none;
      justify-content: center;
      padding: 0;
    }
    .el-form-item__content {
      width: 300px;
      .el-input__wrapper {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
      }
    }
    .el-input__inner {
      border: 1px solid transparent;
      border-radius: 0;
    }
    // .el-input__wrapper{
    //   box-shadow:none;
    // }
    // .el-input__wrapper:hover {
    //     box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
    // }
    // .el-input__inner:focus {
    //   border: 1px solid #003399;
    // }
  }
}
// .el-textarea.is-disabled .el-textarea__inner,
// .el-input.is-disabled .el-input__inner {
//   color: #606266;
// }
</style>
