<template>
  <el-table-column v-if="isShowTableColumn()" v-bind="$attrs"  :label="label" :align="align" :show-overflow-tooltip="showOverflowTooltip">
   <template #header="scope"  v-if="$slots.header" >
      <slot name="header" :$index="scope.$index" :column="scope.column"></slot>
    </template>
    <template #default="scope" v-if="$slots.default">
      <slot  :$index="scope.$index" :row="scope.row" :column="scope.column"></slot>
    </template>
  </el-table-column>
</template>
<script>
export default {
  name:"DlmTableColumn",
  props:{
    showOverflowTooltip:{
      type:Boolean,
      default:true
    },
    label:{
      type:String,
      default:"",
    },
    // width:{
    //   type:String,
    //   default:"200",
    // },
    align:{
      type:String,
      default:"center",
    },
  },
  setup(props,{ attrs,slots, }){
    
    return  {
     }
  },
 
  data () {
    return {
    };
  },
  components: {},

  computed: {},

  mounted() {
  },

  methods: {
    isShowTableColumn(){
      let list =this.$parent.$attrs.labels;
      let label = this.label;
      let obj = list.filter(item=>item.label == label)
      obj = obj.length > 0 ?obj[0] : {}
      return  !obj['label'] || obj.checked;
    },
  }
}

</script>
