import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '@/views/Home.vue'
import purchaseContract from '@/views/purchaseContract/index.vue'
import login from '@/views/login/index.vue'
const routes = [{
    path: '',
    redirect: '/purchaseContract'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '',
    name: 'home',
    component: Home,
    children: [
      // {
      //   path: '/index',
      //   name: 'index',
      //   component: () => import( /* webpackChunkName: "purchaseContract" */ '@/views/index/index.vue')
      // },
      {
        path: '/purchaseContract',
        name: 'purchaseContract',
        component:purchaseContract 
      },
      {
        path: '/purchaseContract/purchaseContractAdd',
        name: 'purchaseContractAdd',
        component: () => import( /* webpackChunkName: "purchaseContractAdd" */ '@/views/purchaseContract/purchaseContractAdd/index.vue')
      }
      
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router