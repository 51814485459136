<template>
  <div class="login_wrap">

    <div class="form_wrap">
      <div class="form_box">
        <div class="title_box">
          <p class="title">德拉姆供应商平台</p>
          <p class="subtitle">长期主义，互相成就</p>
        </div>
        <el-form :model="params" :rules="rules" ref="formRef">
          <el-form-item label="" prop="suppName" style="margin-bottom:25px">
            <el-input v-model="params.suppName" placeholder="请输入账号">
              <template #prefix>
                <el-icon>
                  <UserFilled />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input v-model="params.password" type="password" show-password placeholder="请输入密码">
              <template #prefix>
                <el-icon>
                  <Lock />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="params.saveMe" label="记住密码" />
          </el-form-item>
          <el-form-item label="">
            <el-button class="login_btn" type="primary" size="large" @click="onLogin" :loading="loading">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="el-login-footer">
      <span>
        <a href="https://beian.miit.gov.cn/" target="_blank">版权所有 @2021 图南ERP. All Rights Reserved.
          粤ICP备2021153112号-1</a>
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { login,getUserInfo } from "@/api/login/login";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router =  useRouter();
    const formRef = ref(null);
    const loading = ref(false);
    const rules = {
      suppName: [
        { required: true, message: "请输入账号", trigger: ["blur", "change"] },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: ["blur", "change"] },
      ],
    };
    const params = reactive({
      suppName:"",
      account: "admin", 
      password: "",
      saveMe: false, 
      // systemCode:"ERP" //SUPP_COOPERATE
    });

    const onLogin = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          login(params).then(res=>{
            if(res.resCode == 0){
              getUserInfo().then(res=>{
                if(res.resCode == 0){
                  store.commit("userInfo", res.data);
                }
              })
              router.replace({
                path: "/"
              })
            }
            loading.value = false;
          })
        } 
      });
    };
    return { loading,params, rules, formRef, onLogin };
  },
};
</script>

<style lang="scss" scoped>
.login_wrap {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 51, 153, 0.1), rgba(0, 51, 153, 0.1)),
    url("@/assets/img/loginBg.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100% 100%;
  .title_box {
    text-align: center;
    margin-bottom: 60px;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 16px;
      margin: 20px 0;
    }
  }
  .form_wrap {
    padding-top: 18vh;
    .form_box {
      margin: 0 auto;
      padding: 50px 50px 40px;
      width: 310px;
      background-color: white;
      border-radius: 6px;
    }
    .login_btn {
      width: 100%;
      margin-top: 30px;
    }
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
    white-space: nowrap;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>