<template>
  <div style="padding-left:10px">
    <el-menu
    :default-active="activeIndex"
    class="menu"
    mode="horizontal"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#fff"
    @select="handleSelect"
  >
    <el-sub-menu index="1">
      <template #title>采购单据</template>
      <el-menu-item index="1-1" @click="onMenuItem">采购合同</el-menu-item>
    </el-sub-menu>
  </el-menu>
  </div>
</template>

<script>
import { ref } from "vue";
import tagsRouter from "@/utils/tagsRouter";
export default {
  setup() {
    const activeIndex = ref("1-1");
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const onMenuItem = (key, keyPath) => {
      tagsRouter("采购合同", "/purchaseContract");
    };
    
    return {
      activeIndex,
      handleSelect,
      onMenuItem
    };
  },
};
</script>

<style lang="scss" scoped>
.menu{
  height: 48px;
  border-bottom:none
}
</style>