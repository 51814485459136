import axios from 'axios';
// import store from '@/store'
import message from '@/utils/message';
import router from '@/router'
const instance = axios.create({
  baseURL: "/api"
})

instance.interceptors.request.use(config => {
  return config
}, (err) => {
  return Promise.reject(err)
})


instance.interceptors.response.use((data) => {
  
  return data.data;
}, (err) => {
  return Promise.reject(err)
})
function request(method, url, params) {
  return new Promise((resolve, reject) => {
    let format = method.toLocaleLowerCase() == 'get' ? 'params' : 'data';
    instance({
      method: method,
      url: url,
      [format]: params,
    }).then(res => {
      let {
        resCode,
        data
      } = res;
      if (resCode == 0) {
        resolve(res)

      } else if(resCode == "001106"){
        router.replace({
          path: "/login"
        })
      }else {
        resolve(res)
        message.error(res.errMsg || "请求超时，请重试")

      }
    }).catch(res => {
      reject(res)
    })
  })


  // if(list.indexOf(url) != -1){
    
  // }else{
  //   return new Promise((resolve, reject) => {
  //     ElMessageBox.alert('ChatGPT账号被封，暂停使用', '提示', {
  //       // if you want to disable its autofocus
  //       // autofocus: false,
  //       confirmButtonText: '确定',
  //       callback: (action) => {
  //       },
  //     })
  //     reject({
  //       errMsg:"ChatGPT账号被封，暂停使用"
  //     })
  //   //       message.error(res.errMsg || "请求超时，请重试")
  //   })
  // }
 
 
}
export function post(url, params) {
  return new Promise((resolve, reject) => {
    request("post", url, params).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}
export function get(url, params) {
  return new Promise((resolve, reject) => {
    request("get", url, params).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}
export default request;